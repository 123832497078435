<template>
  <NuxtLayout name="default">
    <section class="flex flex-wrap">
      <div class="flex flex-wrap md:flex-nowrap bg-blue text-white rounded-br-3xl md:pb-16 lg:pb-30 w-full">
        <div
          class="header box-content px-8 pt-8 md:pt-13.5 md:pl-18 md:pr-0 flex w-full md:w-auto lg:flex lg:w-2/3 lg:pt-20 lg:pl-28 lg:items-start xl:pl-40 xl:pt-32"
        >
          <div class="header-inner">
            <h1 class="uppercase text-white pb-4">
              OOPS!<br />
              {{ t("title") }}
            </h1>
            <p class="text-white lg:pt-2.5 pb-8 xl:pb-8 leading-relaxed lg:leading-6">
              {{ t("description") }}
            </p>
            <nuxt-link
              :to="localePath('index') || switchLocalePath('fr', { name: 'index' })"
              class="mr-8 px-8 h-11.5 items-center justify-center bg-black text-white rounded-br-none font-semibold flex self-end md:inline-flex md:mr-8.3/100vw hover:bg-white hover:text-black transition duration-300 xl:mr-0"
            >
              {{ t("back-to-home") }}
            </nuxt-link>
          </div>
        </div>
        <div class="inline-flex header-image justify-end relative w-full mr-20 md:m-0">
          <picture class="w-full block -mr-20 md:-mr-0 md:ml-14">
            <img
              class="rounded-tl-3xl rounded-br-3xl mobile-image"
              srcset="
                /images/404/img-404-haut-mobile@3x.png 3x,
                /images/404/img-404-haut-mobile@2x.png 2x,
                /images/404/img-404-haut-mobile@1x.png 1x
              "
              src="/images/404/img-404-haut-mobile.png"
              :alt="t('title')"
            />
            <img
              class="rounded-tl-3xl rounded-br-3xl tablet-image"
              srcset="
                /images/404/img-404-haut-tablet@3x.png 3x,
                /images/404/img-404-haut-tablet@2x.png 2x,
                /images/404/img-404-haut-tablet@1x.png 1x
              "
              src="/images/404/img-404-haut-tablet.png"
              :alt="t('title')"
            />
            <img
              class="rounded-tl-3xl rounded-br-3xl desktop-image"
              srcset="
                /images/404/img-404-haut-desktop@3x.png 3x,
                /images/404/img-404-haut-desktop@2x.png 2x,
                /images/404/img-404-haut-desktop@1x.png 1x
              "
              src="/images/404/img-404-haut-desktop.png"
              :alt="t('title')"
            />
            <img
              class="rounded-tl-3xl rounded-br-3xl hd-image"
              srcset="
                /images/404/img-404-haut-hd@3x.png 3x,
                /images/404/img-404-haut-hd@2x.png 2x,
                /images/404/img-404-haut-hd@1x.png 1x
              "
              src="/images/404/img-404-haut-hd.png"
              :alt="t('title')"
            />
          </picture>
        </div>
      </div>
      <div class="bg-blue w-full rounded-br-4xl mr-20 h-10 md:hidden">&nbsp;</div>
    </section>
  </NuxtLayout>
</template>

<script>
export default {
  name: "ErrorPage",
  props: {
    error: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();
    return { t, locale, localePath };
  },
};
</script>

<style lang="scss" scoped>
.header {
  @media (min-width: 768px) {
    min-width: 300px;
  }

  &-inner {
    width: 100%;
  }

  h1 {
    max-width: 296px;

    @media (min-width: 768px) {
      max-width: 300px;
    }

    @media (min-width: 1024px) {
      max-width: 516px;
    }

    @media (min-width: 1536px) {
      max-width: 685px;
    }
  }

  p {
    max-width: 269px;

    @media (min-width: 768px) {
      max-width: 292px;
    }

    @media (min-width: 1024px) {
      max-width: 441px;
    }

    @media (min-width: 1024px) {
      max-width: 605px;
    }
  }
}

section {
  @media (min-width: 768px) {
    margin-right: 39.45vw;
  }

  @media (min-width: 1024px) {
    margin-right: 33.33vw;
  }

  @media (min-width: 1366px) {
    margin-right: 456px;
  }

  picture {
    width: 100%;
    max-width: 313px;

    @media (min-width: 768px) {
      max-width: 438px;
    }

    @media (min-width: 768px) {
      max-width: 683px;
    }

    @media (min-width: 1366px) {
      max-width: 959px;
    }
  }
  .mobile-image {
    display: none;

    @media only screen and (max-width: "767px") {
      display: block;
    }
  }

  .tablet-image {
    display: none;
    max-width: 438px;
    @media only screen and ((max-width: "1023px") and (min-width: "768px")) {
      display: block;
    }
  }

  .desktop-image {
    display: none;

    @media only screen and ((max-width: "1355px") and (min-width: "1024px")) {
      display: block;
    }
  }

  .hd-image {
    display: none;

    @media only screen and (min-width: "1356px") {
      display: block;
    }
  }
}
</style>

<i18n lang="json">
{
  "fr": {
    "title": "Il ne semble pas avoir d'informations ici",
    "description": "Apprenez tout sur la lutte contre le tabagisme au Québec et dans le monde. Appel gratuit. Soutien personnalisé. Conseils adapés.",
    "back-to-home": "Retour à l'accueil",
    "alt": "TODO"
  },
  "en": {
    "title": "This page does not exist",
    "description": "Learn more about how to quit smoking here",
    "back-to-home": "Back to home (French)",
    "alt": "TODO"
  }
}
</i18n>
