import gql from "graphql-tag";

export default gql`
  query iStopNow($siteId: [QueryArgument]) {
    page: entry(section: "iStop", siteId: $siteId) {
      title
      ... on iStop_iStop_Entry {
        description
        subtitle
        video
        seo {
          description
          title
        }
        campaignBanner {
          ... on campaignBanner_campaignBanner_BlockType {
            campaignBannerUrl
            campaignBannerImage {
              url
            }
          }
        }
        whiteBanner {
          ... on whiteBanner_whiteBanner_BlockType {
            whiteBannerVideoUrl
            whiteBannerTitle
            whiteBannerText
          }
        }
      }
    }

    hesitate: entry(section: "stillHesitate", siteId: $siteId) {
      title
      ... on stillHesitate_stillHesitate_Entry {
        icon {
          url
        }
        description
        video
        seo {
          title
          description
        }
      }
    }

    hesitateEntries: entries(relatedToCategories: { id: "403" }, siteId: $siteId) {
      ... on article_default_Entry {
        id
        url
        title
        articleTitle
        excerpt
        slug
        icon {
          url
        }
        seoArticle {
          title
          description
        }
      }
    }

    globalBlocks: globalSet(handle: "qst", siteId: $siteId) {
      ... on qst_GlobalSet {
        helpTitle: longTitle
        helpIcon: icon {
          url
        }
        helpDescription: description
        helpBlocks {
          ... on helpBlocks_helpBlock_BlockType {
            id
            blockTitle
            icon {
              url
            }
            blockContent
            linkTitle
            linkAnchor
          }
        }
        proDescription
        accessBlock {
          ... on accessBlock_accessBlock_BlockType {
            id
            blockTitle
            icon {
              url
            }
            description
            descriptionPharmacy
          }
        }
        accessLinks: questionnaireLinks {
          ... on questionnaireLinks_questionnairelink_BlockType {
            questionnaireTitle
            questionnaireNote
            entryLink
          }
        }
      }
    }
  }
`;
