import query from "~/queries/home";

import { mapCategory } from "~/utils/utils";

export default (useAsyncQuery, localePath, locale) => {
  return {
    namespaced: true,
    state() {
      return {
        information: undefined,
        prevention: undefined,
        quit: undefined,
        latest_posts: undefined,
        home_meta: undefined,
        posts: undefined,
      };
    },
    actions: {
      async getData({ commit, getters }) {
        const {
          data: { _value },
        } = await useAsyncQuery(query, { siteId: locale.value === "fr" ? 1 : 2 });
        commit("setPosts", _value.entries);

        const information = _value.home.sectionCategory.find((c) => parseInt(c.id) === 105);
        const prevention = _value.home.sectionCategory.find((c) => parseInt(c.id) === 106);
        const quit = _value.home.sectionCategory.find((c) => parseInt(c.id) === 107);

        const informationItems = _value.informCategories.map((c) => {
          return mapCategory(c, "information", getters.getPostsByTopic(parseInt(c.id)), localePath);
        });

        const preventionItems = _value.preventCategories.map((c) => {
          return mapCategory(c, "prevention", getters.getPostsByTopic(parseInt(c.id)), localePath);
        });

        const quitItems = _value.stopCategories
          .map((c) => {
            return mapCategory(c, "quit", getters.getPostsByTopic(parseInt(c.id)), localePath);
          })
          .map((c) => {
            if (c.id === 403) {
              return Object.assign({}, c, { link: localePath("quit-subpage-index-hesitate-index") });
            }

            if (c.id === 405) {
              c.posts.push({
                title: locale.value === "fr" ? "Aide en ligne" : "Help Online",
                link: locale.value === "fr" ? "/jarrete/inscription" : "/iquitnow/registration",
                blank: true,
              });
              c.posts.push({
                title: locale.value === "fr" ? "Aide par téléphone" : "Help by Phone",
                link: localePath("quit-subpage-index-help-phone"),
              });
              c.posts.push({
                title: locale.value === "fr" ? "Aide en personne" : "Help in Person",
                link: localePath("quit-subpage-index-help-person"),
              });
              c.posts.push({
                title: locale.value === "fr" ? "Aide par texto" : "Help by Text Message",
                link: localePath("quit-subpage-index-help-sms"),
              });

              return Object.assign({}, c, { link: localePath("quit-subpage-index-help") });
            }

            if (c.id === 407) {
              return Object.assign({}, c, { link: localePath("quit-subpage-index-pro") });
            }

            if (c.slug === 'jarrete-en-ligne') {
              return Object.assign({}, c, { link: locale.value === "fr" ? "https://www.quebecsanstabac.ca/jarrete/inscription" : "https://www.tobaccofreequebec.ca/iquitnow/registration"});
            }

            if (c.slug === 'jarrete-par-telephone') {
              return Object.assign({}, c, { link: localePath("quit-subpage-index-help-phone") });
            }

            if (c.slug === 'jarrete-en-personne') {
              return Object.assign({}, c, { link: localePath("quit-subpage-index-help-person") });
            }


            return c;
          });

        commit("setCategoryInformation", {
          title: information.title,
          content: information.description,
          image: information.image?.[0]?.url,
          items: informationItems,
          seo: information.seo,
        });
        
        commit("setCategoryPrevention", {
          title: prevention.title,
          content: prevention.description,
          items: preventionItems,
          image: prevention.image?.[0]?.url,
          seo: prevention.seo,
        });

        commit("setCategoryQuit", {
          title: quit.title,
          content: quit.description,
          items: quitItems,
        });
        
        commit("setLatestPosts", _value.news.map(mapPosts));
        
        let stopSection = _value.home.stopSection[0]
        let facebookSection = _value.home.sectionFacebook[0]
        let preparationSection = _value.home.sectionPreparationHome[0]

        commit("setHomeMeta", {
          title: _value.home.seo.title,
          description: _value.home.seo.description,
          image1: { 
            desktop: _value.home.homeimage1[0] || { url: ''},
            mobile: _value.home.homeimage1[1] || { url: ''}
          },
          image2: { 
            desktop: _value.home.homeimage2[0] || { url: ''},
            mobile: _value.home.homeimage2[1] || { url: ''}
          },
          video: {
            url: _value.home.video,
            title: _value.home.videoTitle
          },
          hero: {
            title: stopSection.sectionTitle,
            body: stopSection.sectionSubtitle
          },
          cards: {
            personBody: stopSection.personSupportContent,
            cellBody: stopSection.telSupportContent,
            onlineBody: stopSection.onlineSupportContent,
          },
          preparation: {
            title: preparationSection.preparationTitle,
            body1: preparationSection.preparationContent1,
            body2: preparationSection.preparationContent2,
            btn: preparationSection.preparationBtn
          },
          main: {
            title: stopSection.supportTitle,
            body: stopSection.supportSubtitle
          },
          facebook: {
            title: facebookSection.facebookTitle,
            body: facebookSection.subtitle,
            join: facebookSection.btn,
            slides: _value.home.sectionFacebook.slice(1)
          }
        });
      },
    },
    mutations: {
      setCategoryInformation(state, categories) {
        state.information = categories;
      },
      setCategoryPrevention(state, categories) {
        state.prevention = categories;
      },
      setCategoryQuit(state, categories) {
        state.quit = categories;
      },
      setLatestPosts(state, posts) {
        state.latest_posts = posts;
      },
      setHomeMeta(state, meta) {
        state.home_meta = meta;
      },
      setPosts(state, posts) {
        state.posts = posts;
      },
    },
    getters: {
      getPostsByTopic: (state) => (id) => {
        if (id === 114) return [];
        if (id === 110) {
          let reorderPost110 = state.posts.filter((post) => post.topicCategory.some((t) => parseInt(t.id) === id));
          return ([reorderPost110[0], reorderPost110[1], reorderPost110[2]] = [
            reorderPost110[2],
            reorderPost110[0],
            reorderPost110[1],
          ]);
        }
        return state.posts.filter((post) => post.topicCategory.some((t) => parseInt(t.id) === id));
      },
    },
  };
};