import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowDown, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoArrowSmall, LazySvgoBurger, LazySvgoCigarette, LazySvgoClose, LazySvgoFb, LazySvgoFb2, LazySvgoFb3, LazySvgoFbRounded, LazySvgoHesitateBubble, LazySvgoIconSlide, LazySvgoIconArrow, LazySvgoIconDesktop, LazySvgoIconPerson, LazySvgoIconPhone, LazySvgoIconPlus, LazySvgoInstagram, LazySvgoLinkedin, LazySvgoLogoCqtsEn, LazySvgoLogoCqtsFr, LazySvgoLogoLeeroy, LazySvgoPlay, LazySvgoPlus, LazySvgoSearch, LazySvgoTwitter } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowDown", LazySvgoArrowDown],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoArrowSmall", LazySvgoArrowSmall],
["SvgoBurger", LazySvgoBurger],
["SvgoCigarette", LazySvgoCigarette],
["SvgoClose", LazySvgoClose],
["SvgoFb", LazySvgoFb],
["SvgoFb2", LazySvgoFb2],
["SvgoFb3", LazySvgoFb3],
["SvgoFbRounded", LazySvgoFbRounded],
["SvgoHesitateBubble", LazySvgoHesitateBubble],
["SvgoIconSlide", LazySvgoIconSlide],
["SvgoIconArrow", LazySvgoIconArrow],
["SvgoIconDesktop", LazySvgoIconDesktop],
["SvgoIconPerson", LazySvgoIconPerson],
["SvgoIconPhone", LazySvgoIconPhone],
["SvgoIconPlus", LazySvgoIconPlus],
["SvgoInstagram", LazySvgoInstagram],
["SvgoLinkedin", LazySvgoLinkedin],
["SvgoLogoCqtsEn", LazySvgoLogoCqtsEn],
["SvgoLogoCqtsFr", LazySvgoLogoCqtsFr],
["SvgoLogoLeeroy", LazySvgoLogoLeeroy],
["SvgoPlay", LazySvgoPlay],
["SvgoPlus", LazySvgoPlus],
["SvgoSearch", LazySvgoSearch],
["SvgoTwitter", LazySvgoTwitter],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
