import { default as indexT0PFoHcckUMeta } from "/var/www/production/qst/pages/index.vue?macro=true";
import { default as index8Stw6NlfeMMeta } from "/var/www/production/qst/pages/information/index.vue?macro=true";
import { default as _91slug_93qY4wE6CGGiMeta } from "/var/www/production/qst/pages/information/subpage/index/[category]/index/[slug].vue?macro=true";
import { default as indexVvvDN21mb9Meta } from "/var/www/production/qst/pages/information/subpage/index/[category]/index/index.vue?macro=true";
import { default as index5PaRDdybCuMeta } from "/var/www/production/qst/pages/information/subpage/index/[category]/index.vue?macro=true";
import { default as _91slug_93Np9bYZ1FXEMeta } from "/var/www/production/qst/pages/information/subpage/index/[category]/news/[slug].vue?macro=true";
import { default as indexemYedTrf6uMeta } from "/var/www/production/qst/pages/information/subpage/index/[category]/news/index.vue?macro=true";
import { default as newswCaoQE2HARMeta } from "/var/www/production/qst/pages/information/subpage/index/[category]/news.vue?macro=true";
import { default as index7aTgS8GpdoMeta } from "/var/www/production/qst/pages/information/subpage/index.vue?macro=true";
import { default as personal_45information_45policyJwVTNzg08CMeta } from "/var/www/production/qst/pages/personal-information-policy.vue?macro=true";
import { default as indexVBmjVD3JPzMeta } from "/var/www/production/qst/pages/prevention/index.vue?macro=true";
import { default as _91slug_938SkGCERnGIMeta } from "/var/www/production/qst/pages/prevention/subpage/index/[category]/index/[slug].vue?macro=true";
import { default as indexIywIrGBzQ6Meta } from "/var/www/production/qst/pages/prevention/subpage/index/[category]/index/index.vue?macro=true";
import { default as indexqKrq78Oiv3Meta } from "/var/www/production/qst/pages/prevention/subpage/index/[category]/index.vue?macro=true";
import { default as indexRWT3VJr98yMeta } from "/var/www/production/qst/pages/prevention/subpage/index.vue?macro=true";
import { default as privacy_45policy5fxTFg6tQnMeta } from "/var/www/production/qst/pages/privacy-policy.vue?macro=true";
import { default as acm_4520217MMeco36KGMeta } from "/var/www/production/qst/pages/quit/forms/acm-2021.vue?macro=true";
import { default as healthcarenZEYDLLpnVMeta } from "/var/www/production/qst/pages/quit/forms/healthcare.vue?macro=true";
import { default as newsletterWH2tABjFoPMeta } from "/var/www/production/qst/pages/quit/forms/newsletter.vue?macro=true";
import { default as orderoEAB3PfrAVMeta } from "/var/www/production/qst/pages/quit/forms/order.vue?macro=true";
import { default as pharmacykevim95VwIMeta } from "/var/www/production/qst/pages/quit/forms/pharmacy.vue?macro=true";
import { default as vapeobdvcClII2Meta } from "/var/www/production/qst/pages/quit/forms/vape.vue?macro=true";
import { default as indexaE8iKdx8PhMeta } from "/var/www/production/qst/pages/quit/index.vue?macro=true";
import { default as indexn9Bm4A7qYhMeta } from "/var/www/production/qst/pages/quit/subpage/index/help/index.vue?macro=true";
import { default as personS7tv4kPOJTMeta } from "/var/www/production/qst/pages/quit/subpage/index/help/person.vue?macro=true";
import { default as phone0NW5a9JAcKMeta } from "/var/www/production/qst/pages/quit/subpage/index/help/phone.vue?macro=true";
import { default as smsOFye8njiMjMeta } from "/var/www/production/qst/pages/quit/subpage/index/help/sms.vue?macro=true";
import { default as confidence_results37EEmy3MtwMeta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence_results.js?macro=true";
import { default as confidenceEys0MvvpjSMeta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence.vue?macro=true";
import { default as _91slug_937y8W71c5cBMeta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/index/[slug].vue?macro=true";
import { default as indexBah4YEdMGoMeta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/index/index.vue?macro=true";
import { default as _91slug_93W9rEdBt2D8Meta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue?macro=true";
import { default as indexbRUi3H91dNMeta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue?macro=true";
import { default as pro6iPF5oKDLLMeta } from "/var/www/production/qst/pages/quit/subpage/index/pro.vue?macro=true";
import { default as indexrzrdB8IgjpMeta } from "/var/www/production/qst/pages/quit/subpage/index.vue?macro=true";
import { default as responsabilityEpvnNPF9gyMeta } from "/var/www/production/qst/pages/responsability.vue?macro=true";
import { default as search8rNoNTgnI6Meta } from "/var/www/production/qst/pages/search.vue?macro=true";
import { default as sharelaX8QHWfMXMeta } from "/var/www/production/qst/pages/share.vue?macro=true";
import { default as sitemapZqwA1kh61aMeta } from "/var/www/production/qst/pages/sitemap.vue?macro=true";
import { default as component_45stubPV7ETPZtNuMeta } from "/var/www/production/qst/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubPV7ETPZtNu } from "/var/www/production/qst/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index___fr",
    path: "/",
    component: () => import("/var/www/production/qst/pages/index.vue")
  },
  {
    name: "information___fr",
    path: "/je-minforme",
    component: () => import("/var/www/production/qst/pages/information/index.vue")
  },
  {
    name: "information-subpage___en",
    path: "/information/subpage",
    component: () => import("/var/www/production/qst/pages/information/subpage/index.vue"),
    children: [
  {
    name: index5PaRDdybCuMeta?.name,
    path: ":category()",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index.vue"),
    children: [
  {
    name: "information-subpage-index-category-index-slug___fr",
    path: "/je-minforme/:post()",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index/[slug].vue")
  },
  {
    name: "information-subpage-index-category-index___fr",
    path: "/je-minforme/:category()",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index/index.vue")
  }
]
  },
  {
    name: newswCaoQE2HARMeta?.name,
    path: ":category()/news",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news.vue"),
    children: [
  {
    name: "information-subpage-index-category-news-slug___fr",
    path: "/je-minforme/actualites/:tag()/:post()",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news/[slug].vue")
  },
  {
    name: "information-subpage-index-category-news___fr",
    path: "/je-minforme/actualites/:tag?",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news/index.vue")
  }
]
  }
]
  },
  {
    name: "information-subpage___fr",
    path: "/information/subpage",
    component: () => import("/var/www/production/qst/pages/information/subpage/index.vue"),
    children: [
  {
    name: index5PaRDdybCuMeta?.name,
    path: "/je-minforme/:category()",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index.vue"),
    children: [
  {
    name: "information-subpage-index-category-index-slug___fr",
    path: ":post()",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index/[slug].vue")
  },
  {
    name: "information-subpage-index-category-index___fr",
    path: "/je-minforme/:category()",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index/index.vue")
  }
]
  },
  {
    name: newswCaoQE2HARMeta?.name,
    path: "/je-minforme/actualites/:tag?",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news.vue"),
    children: [
  {
    name: "information-subpage-index-category-news-slug___fr",
    path: "/je-minforme/actualites/:tag()/:post()",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news/[slug].vue")
  },
  {
    name: "information-subpage-index-category-news___fr",
    path: "/je-minforme/actualites/:tag?",
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news/index.vue")
  }
]
  }
]
  },
  {
    name: "personal-information-policy___fr",
    path: "/politique-des-renseignements-personnels",
    component: () => import("/var/www/production/qst/pages/personal-information-policy.vue")
  },
  {
    name: "personal-information-policy___en",
    path: "/personal-information-policy",
    component: () => import("/var/www/production/qst/pages/personal-information-policy.vue")
  },
  {
    name: "prevention___fr",
    path: "/je-previens",
    component: () => import("/var/www/production/qst/pages/prevention/index.vue")
  },
  {
    name: "prevention-subpage___en",
    path: "/prevention/subpage",
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index.vue"),
    children: [
  {
    name: indexqKrq78Oiv3Meta?.name,
    path: ":category()",
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index.vue"),
    children: [
  {
    name: "prevention-subpage-index-category-index-slug___fr",
    path: "/je-previens/:post()",
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index/[slug].vue")
  },
  {
    name: "prevention-subpage-index-category-index___fr",
    path: "/je-previens/:category()",
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index/index.vue")
  }
]
  }
]
  },
  {
    name: "prevention-subpage___fr",
    path: "/prevention/subpage",
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index.vue"),
    children: [
  {
    name: indexqKrq78Oiv3Meta?.name,
    path: "/je-previens/:category()",
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index.vue"),
    children: [
  {
    name: "prevention-subpage-index-category-index-slug___fr",
    path: ":post()",
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index/[slug].vue")
  },
  {
    name: "prevention-subpage-index-category-index___fr",
    path: "/je-previens/:category()",
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index/index.vue")
  }
]
  }
]
  },
  {
    name: "privacy-policy___fr",
    path: "/politique-de-confidentialite",
    component: () => import("/var/www/production/qst/pages/privacy-policy.vue")
  },
  {
    name: "quit-forms-acm-2021___fr",
    path: "/form/acm-automne-2021",
    component: () => import("/var/www/production/qst/pages/quit/forms/acm-2021.vue")
  },
  {
    name: "quit-forms-acm-2021___en",
    path: "/form/acm-automne-2021-en",
    component: () => import("/var/www/production/qst/pages/quit/forms/acm-2021.vue")
  },
  {
    name: "quit-forms-healthcare___fr",
    path: "/jarrete/acces-professionnels/etablissements-de-sante",
    component: () => import("/var/www/production/qst/pages/quit/forms/healthcare.vue")
  },
  {
    name: "quit-forms-healthcare___en",
    path: "/iquitnow/access/health-facilities",
    component: () => import("/var/www/production/qst/pages/quit/forms/healthcare.vue")
  },
  {
    name: "quit-forms-newsletter___fr",
    path: "/cqts/infolettre",
    component: () => import("/var/www/production/qst/pages/quit/forms/newsletter.vue")
  },
  {
    name: "quit-forms-newsletter___en",
    path: "/cqts/infolettre-en",
    component: () => import("/var/www/production/qst/pages/quit/forms/newsletter.vue")
  },
  {
    name: "quit-forms-order___fr",
    path: "/cqts/commande-jarrete",
    component: () => import("/var/www/production/qst/pages/quit/forms/order.vue")
  },
  {
    name: "quit-forms-order___en",
    path: "/cqts/commande-jarrete-en",
    component: () => import("/var/www/production/qst/pages/quit/forms/order.vue")
  },
  {
    name: "quit-forms-pharmacy___fr",
    path: "/jarrete/acces-professionnels/pharmacies",
    component: () => import("/var/www/production/qst/pages/quit/forms/pharmacy.vue")
  },
  {
    name: "quit-forms-pharmacy___en",
    path: "/iquitnow/access/pharmacies",
    component: () => import("/var/www/production/qst/pages/quit/forms/pharmacy.vue")
  },
  {
    name: "quit-forms-vape___fr",
    path: "/form/envers-vape-anxiete",
    component: () => import("/var/www/production/qst/pages/quit/forms/vape.vue")
  },
  {
    name: "quit-forms-vape___en",
    path: "/form/envers-vape-anxiete-en",
    component: () => import("/var/www/production/qst/pages/quit/forms/vape.vue")
  },
  {
    name: "quit___fr",
    path: "/jarrete",
    component: () => import("/var/www/production/qst/pages/quit/index.vue")
  },
  {
    name: "quit___en",
    path: "/iquitnow",
    component: () => import("/var/www/production/qst/pages/quit/index.vue")
  },
  {
    name: "quit-subpage___en",
    path: "/quit/subpage",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index.vue"),
    children: [
  {
    name: "quit-subpage-index-help___fr",
    path: "/jarrete/jai-besoin-daide",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/index.vue")
  },
  {
    name: "quit-subpage-index-help___en",
    path: "/iquitnow/i-need-help",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/index.vue")
  },
  {
    name: "quit-subpage-index-help-person___fr",
    path: "/jarrete/aide-personne",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/person.vue")
  },
  {
    name: "quit-subpage-index-help-person___en",
    path: "/iquitnow/person-help",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/person.vue")
  },
  {
    name: "quit-subpage-index-help-phone___fr",
    path: "/jarrete/aide-telephone",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/phone.vue")
  },
  {
    name: "quit-subpage-index-help-phone___en",
    path: "/iquitnow/phone-help",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/phone.vue")
  },
  {
    name: "quit-subpage-index-help-sms___fr",
    path: "/jarrete/aide-texto",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/sms.vue")
  },
  {
    name: "quit-subpage-index-help-sms___en",
    path: "/iquitnow/text-help",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/sms.vue")
  },
  {
    name: "quit-subpage-index-hesitate-confidence_results___en",
    path: "hesitate/confidence_results",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence_results.js")
  },
  {
    name: "quit-subpage-index-hesitate-confidence___fr",
    path: "/jarrete/je-me-prepare-a-arreter/votre-confiance",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence.vue")
  },
  {
    name: "quit-subpage-index-hesitate-confidence___en",
    path: "/iquitnow/getting-ready-to-quit/your-confidence",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence.vue")
  },
  {
    name: "quit-subpage-index-hesitate-index-slug___fr",
    path: "/jarrete/je-me-prepare-a-arreter/:post()",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-index-slug___en",
    path: "/iquitnow/getting-ready-to-quit/:post()",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-index___fr",
    path: "/jarrete/je-me-prepare-a-arreter",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-index___en",
    path: "/iquitnow/getting-ready-to-quit",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles-slug___fr",
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/:slug()",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles-slug___en",
    path: "/iquitnow/getting-ready-to-quit/mental-roadblocks/:slug()",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles___fr",
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles___en",
    path: "/iquitnow/getting-ready-to-quit/mental-roadblocks",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue")
  },
  {
    name: "quit-subpage-index-pro___fr",
    path: "/jarrete/acces-professionnels",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/pro.vue")
  },
  {
    name: "quit-subpage-index-pro___en",
    path: "/iquitnow/access-healthcare",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/pro.vue")
  }
]
  },
  {
    name: "quit-subpage___fr",
    path: "/quit/subpage",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index.vue"),
    children: [
  {
    name: "quit-subpage-index-help___fr",
    path: "/jarrete/jai-besoin-daide",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/index.vue")
  },
  {
    name: "quit-subpage-index-help___en",
    path: "/iquitnow/i-need-help",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/index.vue")
  },
  {
    name: "quit-subpage-index-help-person___fr",
    path: "/jarrete/aide-personne",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/person.vue")
  },
  {
    name: "quit-subpage-index-help-person___en",
    path: "/iquitnow/person-help",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/person.vue")
  },
  {
    name: "quit-subpage-index-help-phone___fr",
    path: "/jarrete/aide-telephone",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/phone.vue")
  },
  {
    name: "quit-subpage-index-help-phone___en",
    path: "/iquitnow/phone-help",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/phone.vue")
  },
  {
    name: "quit-subpage-index-help-sms___fr",
    path: "/jarrete/aide-texto",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/sms.vue")
  },
  {
    name: "quit-subpage-index-help-sms___en",
    path: "/iquitnow/text-help",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/sms.vue")
  },
  {
    name: "quit-subpage-index-hesitate-confidence_results___fr",
    path: "hesitate/confidence_results",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence_results.js")
  },
  {
    name: "quit-subpage-index-hesitate-confidence___fr",
    path: "/jarrete/je-me-prepare-a-arreter/votre-confiance",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence.vue")
  },
  {
    name: "quit-subpage-index-hesitate-confidence___en",
    path: "/iquitnow/getting-ready-to-quit/your-confidence",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence.vue")
  },
  {
    name: "quit-subpage-index-hesitate-index-slug___fr",
    path: "/jarrete/je-me-prepare-a-arreter/:post()",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-index-slug___en",
    path: "/iquitnow/getting-ready-to-quit/:post()",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-index___fr",
    path: "/jarrete/je-me-prepare-a-arreter",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-index___en",
    path: "/iquitnow/getting-ready-to-quit",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles-slug___fr",
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/:slug()",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles-slug___en",
    path: "/iquitnow/getting-ready-to-quit/mental-roadblocks/:slug()",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles___fr",
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles___en",
    path: "/iquitnow/getting-ready-to-quit/mental-roadblocks",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue")
  },
  {
    name: "quit-subpage-index-pro___fr",
    path: "/jarrete/acces-professionnels",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/pro.vue")
  },
  {
    name: "quit-subpage-index-pro___en",
    path: "/iquitnow/access-healthcare",
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/pro.vue")
  }
]
  },
  {
    name: "responsability___fr",
    path: "/conditions-utilisation",
    meta: responsabilityEpvnNPF9gyMeta || {},
    component: () => import("/var/www/production/qst/pages/responsability.vue")
  },
  {
    name: "search___fr",
    path: "/recherche",
    component: () => import("/var/www/production/qst/pages/search.vue")
  },
  {
    name: "share___fr",
    path: "/progression",
    component: () => import("/var/www/production/qst/pages/share.vue")
  },
  {
    name: "share___en",
    path: "/progress",
    component: () => import("/var/www/production/qst/pages/share.vue")
  },
  {
    name: "sitemap___fr",
    path: "/plan-du-site",
    component: () => import("/var/www/production/qst/pages/sitemap.vue")
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/enjeux-actuels/un-megot-ca-va-dans-les-poubelles-ca-coule-de-source",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/campagnes-mediatiques/semaine-pour-un-quebec-sans-tabac-pubs-et-temoignages",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/semaine-pour-un-quebec-sans-tabac-2024",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/avis-non-responsabilite",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/poursuites-judiciaires/recours-collectifs-quebecois-un-nouveau-chapitre-en",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/promotion-des-produits-de-vapotage-le-gouvernement",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/levons-notre-verre-la-2e-saison-des-terrasses-sans-fumee",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/lancement-de-la-strategie-pour-un-quebec-sans-tabac-2020",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/cinq-raisons-meconnues-de-hausser-la-taxe-sur-les",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/adoption-du-projet-de-loi-s-5-la-cigarette-electronique",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/arret-tabagique/lecole-secondaire-du-rocher-adopte-la-toute-premiere$",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/arret-tabagique/le-secret-du-controle-de-soi-comportement-sante-et$",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/dangers-sante-cigarette",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/dependance/bienfaits-arretert",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/formes-tabac",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/industrie-tabac/stratégies-marketing-cigarettiers",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/produits-de-vapotage/lois-et-indusrie",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/produits-de-vapotage-et-cigarette-electronique/cest-quoi",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-previens/ecoles-organismes-jeunesse",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-previens/maison",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/deni",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jeminforme/industrie-tabac;",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarete/aide-personne",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/jai-besoin-daide",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/vos-envies",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/votre-confiance",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/arreter-de-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/arreter-de-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/benefices-sante",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/benefices-sante",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/craindre-sevrage",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/craindre-sevrage",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/dangers-cigarette",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/dangers-cigarette",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/grand-pere",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/grand-pere",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/panique-cesser-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/panique-cesser-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/trucs-cesser-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/trucs-cesser-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/campagnes-mediatiques/semaine-pour-un-quebec-sans-tabac-2017",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/tabas-toutes-formes/types-fumees-tabac",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/cqts/:pathMatch(.*)",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/node/:pathMatch(.*)",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/www.quebecsanstabac.ca/je-previens",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/www.quebecsanstabac.ca/je-minforme",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/www.quebecsanstabac.ca/jarrete",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/www.quebecsanstabac.ca/iquitnow",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/www.quebecsanstabac.ca/",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/prevention",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-previens/projets/www.quebecsanstabac.ca/je-previens/projets/activites-cles-en-main",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-previens/outils/www.quebecsanstabac.ca/je-previens/outils/cessation",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-mmforme/consequences-societe/impacts-economiques",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/www.quebecsanstabac.ca/je-minforme/tabac-toutes-formes",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/www.quebecsanstabac.ca/je-minforme/industrie-tabac",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/www.quebecsanstabac.ca/je-minforme/consequences-societe",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/tabac-toutes-formes/www.quebecsanstabac.ca/je-minforme/tabac-toutes-formes/types-fumees-tabac",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/tabac-toutes-formes/composants-",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/produits-de-vapotage/www.quebecsanstabac.ca/je-minforme/produits-de-vapotage/utilisation-chez-les-jeunes",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/produits-de-vapotage/www.quebecsanstabac.ca/je-minforme/produits-de-vapotage/lois-et-industrie",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/produits-de-vapotage/www.quebecsanstabac.ca/je-minforme/produits-de-vapotage/cest-quoi",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/industrie-tabac/www.quebecsanstabac.ca/je-minforme/industrie-tabac/strategies-marketing-cigarettiers",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/industrie-tabac/www.quebecsanstabac.ca/je-minforme/industrie-tabac/debuts-vente-tabac",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/dependance/www.quebecsanstabac.ca/je-minforme/dependance/initiation-tabagique",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/dependance/www.quebecsanstabac.ca/je-minforme/dependance/comprendre-dependance",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/dangers-sante/www.quebecsanstabac.ca/je-minforme/dangers-sante/tabac-cancers",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/dangers-sante/www.quebecsanstabac.ca/je-minforme/dangers-sante/sexualite-grossesse",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/dangers-sante/www.quebecsanstabac.ca/je-minforme/dangers-sante/effets-negatifs-animaux",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/www.quebecsanstabac.ca/je-minforme/actualites/campagnes",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/tag1",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/projets/www.quebecsanstabac.ca/je-minforme/actualites/projets/des-entreprises-manufacturieres-sengagent-contre-le-tabagisme",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/www.quebecsanstabac.ca/je-minforme/actualites/lois-et-reglements/preserver-la-sante-est-la-meilleure-maniere-de-diminuer-la-pression-sur-le-systeme-de-soins",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/www.quebecsanstabac.ca/je-minforme/actualites/lois-et-reglements/messages-dissuasifs-sur-les-cigarettes-le-canada-frappe-fort",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/www.quebecsanstabac.ca/je-minforme/actualites/lois-et-reglements/levons-notre-verre-a-la-2e-saison-des-terrasses-sans-fumee",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/inspiration-temoignages/www.quebecsanstabac.ca/je-minforme/actualites/inspiration-temoignages/stephane-libre-de-la-cigarette-apres-30-ans",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/enjeux-actuels/www.quebecsanstabac.ca/je-minforme/actualites/enjeux-actuels/sst-prevention-du-tabagisme-en-entreprise",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/enjeux-actuels/www.quebecsanstabac.ca/je-minforme/actualites/enjeux-actuels/les-7-principaux-mythes-sur-le-vapotage",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/enjeux-actuels/www.quebecsanstabac.ca/je-minforme/actualites/enjeux-actuels/le-radon-les-fumeurs-plus-risque-que-les-non-fumeurs",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/enjeux-actuels/www.quebecsanstabac.ca/je-minforme/actualites/enjeux-actuels/journee-mondiale-sans-tabac-sengager-arreter",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/enjeux-actuels/www.quebecsanstabac.ca/je-minforme/actualites/enjeux-actuels/briser-le-mythe-non-vapoter-ne-reduit-pas-lanxiete",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/enjeux-actuels/www.quebecsanstabac.ca/je-minforme/actualites/enjeux-actuels/1er-juillet-proprietaires-frileux-locataires-fumeurs-en-difficulte",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/enjeux-actuels/www.quebecsanstabac.ca/je-minforme/actualites/enjeux-actuels/10-raisons-dinterdire-lusage-du-tabac-dans-les-logements",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/enjeux-actuels/www.quebecsanstabac.ca/je-minforme/actualites/enjeux-actuels/10-mythes-sur-le-tabagisme",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/communique-de-presse/www.quebecsanstabac.ca/je-minforme/actualites/communique-de-presse/transformation-des-boutiques-de-vapotage-pour-attirer-les-mineurs",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/campagnes-mediatiques/www.quebecsanstabac.ca/je-minforme/actualites/campagnes-mediatiques/pour-des-ptits-poumons-roses-et-en-sante",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/campagnes-mediatiques/www.quebecsanstabac.ca/je-minforme/actualites/campagnes-mediatiques/le-defi-jarrete-jy-gagne-pour-ecraser-et-profiter-des-bienfaits",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/blogue/www.quebecsanstabac.ca/je-minforme/actualites/blogue/le-pharmacien-un-allie-dans-larret-tabagique-rencontre-avec-le-duo-carl-et-kate",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/blogue/www.quebecsanstabac.ca/je-minforme/actualites/blogue/arret-tabagique-et-prise-de-poids-les-reponses-a-vos-questions",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/je-minforme/actualites/7-conseils-pour-ne-pas-rallumer-une-cigarette-lete",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/www.quebecsanstabac.ca/jarrete/je-me-prepare-a-arreter/pensees-obstacles/trucs-cesser-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/www.quebecsanstabac.ca/jarrete/je-me-prepare-a-arreter/pensees-obstacles/pire-que-la-cigarette",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/www.quebecsanstabac.ca/jarrete/je-me-prepare-a-arreter/pensees-obstacles/panique-cesser-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/www.quebecsanstabac.ca/jarrete/je-me-prepare-a-arreter/pensees-obstacles/grand-pere",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/www.quebecsanstabac.ca/jarrete/je-me-prepare-a-arreter/pensees-obstacles/dangers-cigarette",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/www.quebecsanstabac.ca/jarrete/je-me-prepare-a-arreter/pensees-obstacles/craindre-sevrage",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/www.quebecsanstabac.ca/jarrete/je-me-prepare-a-arreter/pensees-obstacles/benefices-sante",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/www.quebecsanstabac.ca/jarrete/je-me-prepare-a-arreter/pensees-obstacles/arreter-de-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/worse-things-than-cigarettes",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/vaccine-quitting-smoking",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/stress-smoking",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/stop-without-fattening",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/smoking-my-choice",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/panic-quitting-smoking",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/others-reactions",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/life-boring",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/health-benefits",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/grandfather",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/government-would-ban-it",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/dangers-smoking",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/cannot-stop-willpower",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/afraid-withdrawal",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/afraid-of-relapse",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/services-de-soutien-a-labandon-du-tabagisme-et-du-vapotage-centres-dabandon-du-tabagisme-du-quebec",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/worse-things-than-cigarettes",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/vie-ennuyante",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/vaccine-quitting-smoking",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/vaccin",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/tips-quitting-smoking",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/stress-smoking",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/stop-without-fattening",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/smoking-my-choice",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/rechute-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/reactions-entourage",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/pire-que-la-cigarette",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/panic-quitting-smoking",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/others-reactions",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/natural-cigarettes",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/mourir-de-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/life-boring",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/interdiction-de-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/health-benefits",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/government-would-ban-it",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/fumer-stress",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/dying-from-smoking",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/dangers-smoking",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/continuer-a-fumer",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/cigarettes-naturelles",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/cannot-stop-willpower",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/arreter-sans-grossir",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/afraid-withdrawal",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/afraid-of-relapse",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/aide-texto&quot;",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/aide-telephone&quot;",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/aide-personne&quot;",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/acces-professionnels/www.quebecsanstabac.ca/jarrete/acces-professionnels/pharmacies",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/acces-professionnels/www.quebecsanstabac.ca/jarrete/acces-professionnels/etablissements-de-sante",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/jarrete/acces-professionnels/pharmacie",
    component: component_45stubPV7ETPZtNu
  },
  {
    name: component_45stubPV7ETPZtNuMeta?.name,
    path: "/information",
    component: component_45stubPV7ETPZtNu
  }
]