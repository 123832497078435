import { mapArticles } from "~/utils/utils";

import query from "~/queries/quit";
import postQuery from "~/queries/post";


export default (useAsyncQuery, localePath, locale) => {
  const HESITATE_OBSTACLE_ID = 1883;
  const HESITATE_DESIRE_ID = 1885;
  const HESITATE_CONFIDENCE_ID = 1887;

  return {
    namespaced: true,
    state() {
      return {
        title: undefined,
        subtitle: undefined,
        description: undefined,
        video: undefined,
        hesitate: undefined,
        help: undefined,
        pro: undefined,
        seo: undefined,
        campaign: undefined,
        banner: undefined,
      };
    },
    getters: {
      getDesire: (state) => {
        return state.hesitate.articles.find((post) => post.id === HESITATE_DESIRE_ID);
      },
      getConfidence: (state) => {
        return state.hesitate.articles.find((post) => post.id === HESITATE_CONFIDENCE_ID);
      },
      getObstacle: (state) => {
        return state.hesitate.articles.find((post) => post.id === HESITATE_OBSTACLE_ID);
      },
    },
    mutations: {
      setHesitate(state, content) {
        let data = content.value
        let confidenceTest = content.article.confidencetest?.[0]

        state.hesitate = {
          title: data.hesitate.title,
          description: data.hesitate.description,
          video: data.hesitate.video,
          icon: data.hesitate.icon.length > 0 ? data.hesitate.icon[0].url : false,
          confidenceTest: {
            title: confidenceTest?.titleTest,
            subtitle: confidenceTest?.subtitleTest,
            placeholder: confidenceTest?.placeholderTest,
            see: confidenceTest?.seeResult,
            next: confidenceTest?.nextQuestion,
            previous: confidenceTest?.previousQuestion,
            results: {
              low: confidenceTest?.lowResult,
              medium: confidenceTest?.okResult,
              high: confidenceTest?.highResult
            },
            bilan: {
              low: confidenceTest?.bilanPetit,
              medium: confidenceTest?.bilanMoyen,
              high: confidenceTest?.bilanGrand
            },
            questions: {
              question1: confidenceTest?.question1, 
              question2: confidenceTest?.question2, 
              question3: confidenceTest?.question3, 
              question4: confidenceTest?.question4, 
              question5: confidenceTest?.question5, 
              question6: confidenceTest?.question6, 
              question7: confidenceTest?.question7, 
              question8: confidenceTest?.question8,
              question9: confidenceTest?.question9
            },
            answers: {
              answer1: confidenceTest?.answer1,
              answer2: confidenceTest?.answer2,
              answer3: confidenceTest?.answer3,
              answer4: confidenceTest?.answer4,
            }
          },
          seo: {
            title: data.hesitate.seo.title,
            description: data.hesitate.seo.description,
          },
          articles: data.hesitateEntries.map((entry) => mapArticles(entry, localePath)),
        };
      },
      setHelp(state, data) {
        state.help = data;
      },
      setPro(state, data) {
        state.pro = {
          title: data.globalBlocks.accessBlock[0].blockTitle,
          description: data.globalBlocks.accessBlock[0].description,
          descriptionPharmacy: data.globalBlocks.accessBlock[0].descriptionPharmacy,
          descriptionPro: data.globalBlocks.proDescription,
          icon: data.globalBlocks.accessBlock[0].icon.length > 0 ? data.globalBlocks.accessBlock[0].icon[0].url : false,
          links: data.globalBlocks.accessLinks.map((l) => {
            return {
              title: l.questionnaireTitle,
              link: l.entryLink,
            };
          }),
        };
      },
      setSeo(state, data) {
        state.seo = {
          title: data.page.seo.title,
          description: data.page.seo.description,
        };
      },
      setTitle(state, title) {
        state.title = title;
      },
      setSubtitle(state, subtitle) {
        state.subtitle = subtitle;
      },
      setDescription(state, description) {
        state.description = description;
      },
      setVideo(state, src) {
        state.video = src;
      },
      setCampaign(state, data) {
        state.campaign = {
          url: data.page.campaignBanner?.[0]?.campaignBannerUrl,
          image: data.page.campaignBanner?.[0]?.campaignBannerImage?.[0]?.url,
        };
      },
      setBanner(state, data) {
        state.banner = {
          url: data.page.whiteBanner?.[0]?.whiteBannerVideoUrl,
          title: data.page.whiteBanner?.[0]?.whiteBannerTitle,
          text: data.page.whiteBanner?.[0]?.whiteBannerText,
        };
      },
    },
    actions: {
      async fetchIndex({ commit }) {
        const {
          data: { _value },
        } = await useAsyncQuery(query, { siteId: locale.value === "fr" ? 1 : 2 });
        
        const {
          data: {
            _value: { article },
          },
        } = await useAsyncQuery(postQuery, { siteId: locale.value === "fr" ? 1 : 2, slug: locale.value === "fr" ? "votre-confiance" : "your-confidence" });

        commit("setTitle", _value.page.title);
        commit("setSubtitle", _value.page.subtitle);
        commit("setDescription", _value.page.description);
        commit("setVideo", _value.page.video);
        commit("setHesitate", {value: _value, article: article});
        commit("setPro", _value);
        commit("setSeo", _value);
        commit("setBanner", _value);
        commit("setCampaign", _value);

        // A bit lazy, end of project where the client changed the content architecture...
        commit("setHelp", {
          title: _value.globalBlocks.helpTitle,
          description: _value.globalBlocks.helpDescription,
          icon: _value.globalBlocks.helpIcon.length > 0 ? _value.globalBlocks.helpIcon[0].url : false,
          articles: _value.globalBlocks.helpBlocks.map((block) => {
            const helpPathPhone = localePath("quit-subpage-index-help-phone");
            const helpPathPerson = localePath("quit-subpage-index-help-person");
            const helpPathSms = localePath("quit-subpage-index-help-sms");

            let link;
            const id = parseInt(block.id);

            if (id === 952) {
              link = helpPathPhone;
            } else if (id === 953) {
              link = helpPathPerson;
            } else if (id === 954) {
              link = helpPathSms;
            } else if (id === 19925) {
              return {
                id,
                title: block.blockTitle,
                excerpt: block.blockContent,
                icon: block.icon.length > 0 ? block.icon[0].url : false,
                slug: block.slug,
                href: locale.value === "fr" ? "/jarrete/inscription" : "/iquitnow/registration",
                blank: true,
              };
            } else {
              link = id;
            }

            return {
              id,
              title: block.blockTitle,
              excerpt: block.blockContent,
              icon: block.icon.length > 0 ? block.icon[0].url : false,
              slug: block.slug,
              link,
            };
          }),
        });
      },
    },
  };
};
