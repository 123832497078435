import { createStore } from "vuex";

import createWebStore from "~/stores/web";
import createQuitStore from "~/stores/quit";

export default defineNuxtPlugin(async (nuxtApp) => {
  const { locale } = nuxtApp.$i18n;

  const store = createStore({
    modules: {
      web: createWebStore(useAsyncQuery, nuxtApp.$localePath, locale),
      quit: createQuitStore(useAsyncQuery, nuxtApp.$localePath, locale),
    },
    state() {
      return {
        prevent_lang_change: false,
      };
    },
    mutations: {
      preventLangChange(state, preventLangChange) {
        state.prevent_lang_change = preventLangChange;
      },
    },
  });

  nuxtApp.vueApp.use(store);

  // Retry when api fails

  for (let i = 0; i <= 4; i++) {
    try {
      await store.dispatch("web/getData");
      await store.dispatch("quit/fetchIndex");

      break;
    } catch (e) {
      if (i === 4) {
        throw e;
      }
    }
  }

  return {
    provide: {
      store,
    },
  };
});
