export default defineNuxtRouteMiddleware((to, from) => {
    const config = useRuntimeConfig();
    const url = useRequestURL()
    const host = url.origin;

    if (host.match(config.public.enDomain.replace(/(http(s)?:\/\/)?(www\.)?/, "")) ) {
         if (to.path === '/sitemap$') {
            return navigateTo(`${config.public.frDomain}/plan-du-site`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/www.tobaccofreequebec.ca/plan-du-site') {
            return navigateTo(`${config.public.frDomain}/plan-du-site`, {
               external: true,
               redirectCode: 301
            });
         }  else if (to.path === '/form/patient-reference-form-from-hosp') {
            return navigateTo(`/iquitnow/access/health-facilities`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/form/patient-reference-form-from-phar') {
            return navigateTo(`/iquitnow/access/pharmacies`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/access/healthcare') {
            return navigateTo(`/iquitnow/access/health-facilities`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/access/pharmacy') {
            return navigateTo(`/iquitnow/access/pharmacies`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/access$') {
            return navigateTo(`/iquitnow/access-healthcare`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/access-pharmacy') {
            return navigateTo(`/iquitnow/access/health-facilities`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/i-need-help') {
            return navigateTo(`/iquitnow`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/services-de-soutien-a-labandon-du-tabagisme-et-du-vapotage-centres-dabandon-du-tabagisme-du-quebec') {
            return navigateTo(`${config.public.enDomain}/iquitnow/getting-ready-to-quit/services-de-soutien-a-labandon-du-tabagisme-et-du-vapotage-centres-dabandon-du-tabagisme-du-quebec`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/your-confidence') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/your-confidence`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/afraid-withdrawal') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-withdrawal`, {
               external: false,
               redirectCode: 301
            });
         }  else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/life-boring') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/life-boring`, {
               external: false,
               redirectCode: 301
            });
         }  else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/stop-without-fattening') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stop-without-fattening`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/health-benefits') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/health-benefits`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblock/afraid-withdrawal') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-withdrawal`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblock/life-boring') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/life-boring`, {
               external: false,
               redirectCode: 301
            });
         }  else if (to.path === '/iquitnow/still-hesitating/mental-roadblock/stop-without-fattening') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stop-without-fattening`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblock/health-benefits') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/health-benefits`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating') {
            return navigateTo(`/iquitnow/getting-ready-to-quit`, {
               external: false,
               redirectCode: 301
            });
         }else if (to.path === '/www.tobaccofreequebec.ca/politique-de-confidentialite') {
            return navigateTo(`${config.public.frDomain}/politique-de-confidentialite`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/www.tobaccofreequebec.ca/plan-du-site') {
            return navigateTo(`${config.public.frDomain}/plan-du-site`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/www.tobaccofreequebec.ca/je-previens') {
            return navigateTo(`${config.public.frDomain}/je-previens`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/www.tobaccofreequebec.ca/je-minforme') {
            return navigateTo(`${config.public.frDomain}/je-minforme`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/www.tobaccofreequebec.ca/iquitnow') {
            return navigateTo(`/iquitnow`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/www.tobaccofreequebec.ca/') {
            return navigateTo(`${config.public.frDomain}/`, {
               external: true,
               redirectCode: 301
            });
         }  else if (to.path === '/responsability') {
            return navigateTo(`${config.public.frDomain}/conditions-utilisation`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/prevention') {
            return navigateTo(`${config.public.frDomain}/je-previens`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-previens/projets') {
            return navigateTo(`${config.public.frDomain}/je-previens/projets`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-previens/outils') {
            return navigateTo(`${config.public.frDomain}/je-previens/outils`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-previens/initiatives') {
            return navigateTo(`${config.public.frDomain}/je-previens/initiatives`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/www.tobaccofreequebec.ca/je-minforme/actualites') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/tabac-toutes-formes/produits-tabac') {
            return navigateTo(`${config.public.frDomain}/je-minforme/tabac-toutes-formes/produits-tabac`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/tabac-toutes-formes') {
            return navigateTo(`${config.public.frDomain}/je-minforme/tabac-toutes-formes`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/produits-de-vapotage/utilisation-chez-les-jeunes') {
            return navigateTo(`${config.public.frDomain}/je-minforme/produits-de-vapotage/utilisation-chez-les-jeunes`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/produits-de-vapotage') {
            return navigateTo(`${config.public.frDomain}/je-minforme/produits-de-vapotage`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/industrie-tabac/www.tobaccofreequebec.ca/je-minforme/industrie-tabac/proces-marquants') {
            return navigateTo(`${config.public.frDomain}/je-minforme/industrie-tabac/proces-marquants`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/industrie-tabac/strategies-marketing-cigarettiers') {
            return navigateTo(`${config.public.frDomain}/je-minforme/industrie-tabac/strategies-marketing-cigarettiers`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/dependance/www.tobaccofreequebec.ca/je-minforme/dependance/bienfaits-de-larret-du-vapotage') {
            return navigateTo(`${config.public.frDomain}/je-minforme/dependance/bienfaits-de-larret-du-vapotage`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/dependance/initiation-tabagique') {
            return navigateTo(`${config.public.frDomain}/je-minforme/dependance/initiation-tabagique`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/dependance/bienfaits-arreter') {
            return navigateTo(`${config.public.frDomain}/je-minforme/dependance/bienfaits-arreter`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/dependance') {
            return navigateTo(`${config.public.frDomain}/je-minforme/dependance`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/dangers-sante/cigarette-pilule-contraceptive') {
            return navigateTo(`${config.public.frDomain}/je-minforme/dangers-sante/cigarette-pilule-contraceptive`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/dangers-sante') {
            return navigateTo(`${config.public.frDomain}/je-minforme/dangers-sante`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/consequences-societe/tabac-impacts-environnement') {
            return navigateTo(`${config.public.frDomain}/je-minforme/consequences-societe/tabac-impacts-environnement`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/consequences-societe/augmentation-pauvrete') {
            return navigateTo(`${config.public.frDomain}/je-minforme/consequences-societe/augmentation-pauvrete`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/consequences-societe') {
            return navigateTo(`${config.public.frDomain}/je-minforme/consequences-societe`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/www.tobaccofreequebec.ca/je-minforme/actualites/poursuites-judiciaires') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/poursuites-judiciaires`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/www.tobaccofreequebec.ca/je-minforme/actualites/campagnes-mediatiques') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/campagnes-mediatiques/le-defi-jarrete-jy-gagne-pour-ecraser-et-profiter-des-bienfaits`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/temoignage-et-inspiration/www.tobaccofreequebec.ca/je-minforme/actualites/temoignage-et-inspiration/stephane-libre-de-la-cigarette-apres-30-ans') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/inspiration-temoignages/stephane-libre-de-la-cigarette-apres-30-ans`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/enjeux-actuels/www.tobaccofreequebec.ca/je-minforme/actualites/enjeux-actuels/reglement-interdisant-les-saveurs-des-fumeurs-vapoteurs-amers-envers-lindustrie') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/enjeux-actuels/reglement-interdisant-les-saveurs-des-fumeurs-vapoteurs-amers-envers-lindustrie`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/enjeux-actuels/www.tobaccofreequebec.ca/je-minforme/actualites/enjeux-actuels/le-quebec-augmente-pour-la-deuxieme-fois-en-deux-ans-la-taxe-sur-le-tabac') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/enjeux-actuels/le-quebec-augmente-pour-la-deuxieme-fois-en-deux-ans-la-taxe-sur-le-tabac`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/enjeux-actuels/www.tobaccofreequebec.ca/je-minforme/actualites/enjeux-actuels/journee-mondiale-sans-tabac-loms-expose-lindustrie-du-tabac') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/enjeux-actuels/journee-mondiale-sans-tabac-loms-expose-lindustrie-du-tabac`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/enjeux-actuels/www.tobaccofreequebec.ca/je-minforme/actualites/enjeux-actuels/briser-le-mythe-vapotage-plus-ou-moins-dangereux-que-la-cigarette-dangereux-point') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/enjeux-actuels/briser-le-mythe-vapotage-plus-ou-moins-dangereux-que-la-cigarette-dangereux-point`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/enjeux-actuels/www.tobaccofreequebec.ca/je-minforme/actualites/enjeux-actuels/briser-le-mythe-non-vapoter-ne-reduit-pas-lanxiete') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/enjeux-actuels/briser-le-mythe-non-vapoter-ne-reduit-pas-lanxiete`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/communique-de-presse/www.tobaccofreequebec.ca/je-minforme/actualites/communique-de-presse/transformation-des-boutiques-de-vapotage-pour-attirer-les-mineurs') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/communique-de-presse/transformation-des-boutiques-de-vapotage-pour-attirer-les-mineurs`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/campagnes/www.tobaccofreequebec.ca/je-minforme/actualites/campagnes/le-defi-jarrete-jy-gagne-2024-reunit-fumeurs-et-vapoteurs') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/campagnes/le-defi-jarrete-jy-gagne-2024-reunit-fumeurs-et-vapoteurs`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/campagnes/www.tobaccofreequebec.ca/je-minforme/actualites/campagnes/bye-bye-au-tabagisme-avec-jarrete') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/campagnes/bye-bye-au-tabagisme-avec-jarrete`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/campagnes-mediatiques/www.tobaccofreequebec.ca/je-minforme/actualites/campagnes-mediatiques/semaine-pour-un-quebec-sans-tabac-2023') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/campagnes-mediatiques/semaine-pour-un-quebec-sans-tabac-2023`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/campagnes-mediatiques/www.tobaccofreequebec.ca/je-minforme/actualites/campagnes-mediatiques/semaine-pour-un-quebec-sans-tabac-2020') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/campagnes-mediatiques/semaine-pour-un-quebec-sans-tabac-2020`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/campagnes-mediatiques/www.tobaccofreequebec.ca/je-minforme/actualites/campagnes-mediatiques/nick-suzuki-maxence-parrot-felix-auger-aliassime-kristel-ngarlem-eve-gascon-samuel-piette-et-kim-clavel-brisent-lillusion') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/campagnes-mediatiques/nick-suzuki-maxence-parrot-felix-auger-aliassime-kristel-ngarlem-eve-gascon-samuel-piette-et-kim-clavel-brisent-lillusion`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/campagnes-mediatiques/www.tobaccofreequebec.ca/je-minforme/actualites/campagnes-mediatiques/le-defi-jarrete-jy-gagne-pour-ecraser-et-profiter-des-bienfaits') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/campagnes-mediatiques/le-defi-jarrete-jy-gagne-pour-ecraser-et-profiter-des-bienfaits`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/campagnes-mediatiques/www.tobaccofreequebec.ca/je-minforme/actualites/campagnes-mediatiques/journee-mondiale-sans-tabac-le-secret-est-devoile') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/campagnes-mediatiques/journee-mondiale-sans-tabac-le-secret-est-devoile`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/blogue/www.tobaccofreequebec.ca/je-minforme/actualites/blogue/demenager-loccasion-ideale-pour-eliminer-le-tabac-de-sa-vie') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/blogue/demenager-loccasion-ideale-pour-eliminer-le-tabac-de-sa-vie`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/blogue/www.tobaccofreequebec.ca/je-minforme/actualites/blogue/6-conseils-pour-vous-preparer-a-arreter-de-fumer-en-2024') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/blogue/6-conseils-pour-vous-preparer-a-arreter-de-fumer-en-2024`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/blogue/www.tobaccofreequebec.ca/je-minforme/actualites/blogue/5-conseils-pour-faire-face-au-stress-sans-allumer-une-cigarette') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/blogue/6-conseils-pour-faire-face-au-stress-sans-allumer-une-cigarette`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow&quot;') {
            return navigateTo(`/iquitnow`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/www.tobaccofreequebec.ca/iquitnow/text-help') {
            return navigateTo(`/iquitnow/text-help`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/www.tobaccofreequebec.ca/iquitnow/phone-help') {
            return navigateTo(`/iquitnow/phone-help`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/www.tobaccofreequebec.ca/iquitnow/person-help') {
            return navigateTo(`/iquitnow/person-help`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/www.tobaccofreequebec.ca/iquitnow/person-help?msclkid=c564d18acfc811ecb28a0296cccaffb2') {
            return navigateTo(`/iquitnow/person-help`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/www.tobaccofreequebec.ca/iquitnow/i-need-help') {
            return navigateTo(`/iquitnow`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/your-cravings') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/your-cravings`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/your-cravings?amp=1') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/your-cravings`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/services-de-soutien-a-labandon-du-tabagisme-et-du-vapotage-centres-dabandon-du-tabagisme-du-quebec') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/services-de-soutien-a-labandon-du-tabagisme-et-du-vapotage-centres-dabandon-du-tabagisme-du-quebec`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/worse-things-than-cigarettes') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/worse-things-than-cigarettes`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/vie-ennuyante') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/life-boring`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/vaccine-quitting-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/vaccine-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/vaccin') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/vaccine-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/trucs-cesser-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/tips-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/tips-quitting-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/tips-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/stress-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stress-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/smoking-my-choice') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/smoking-my-choice`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/rechute-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-of-relapse`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/reactions-entourage') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/others-reactions`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/pire-que-la-cigarette') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/worse-things-than-cigarettes`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/panique-cesser-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/panic-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/panic-quitting-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/panic-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/others-reactions') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/others-reactions`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/natural-cigarettes') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/natural-cigarettes`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/mourir-de-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/dying-from-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/interdiction-de-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/government-would-ban-it`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/grandfather') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/grandfather`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/grand-pere') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/grandfather`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/government-would-ban-it') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/government-would-ban-it`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/fumer-stress') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stress-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/dying-from-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/dying-from-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/dangers-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/dangers-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/dangers-cigarette') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/dangers-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/craindre-sevrage') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-withdrawal`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/continuer-a-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/smoking-my-choice`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/cigarettes-naturelles') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/natural-cigarettes`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/cannot-stop-willpower') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/cannot-stop-willpower`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/arreter-sans-grossir') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stop-without-fattening`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/arreter-de-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/cannot-stop-willpower`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/afraid-of-relapse') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-of-relapse`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/www.tobaccofreequebec.ca/iquitnow/getting-ready-to-quit/mental-roadblocks/stop-without-fattening') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stop-without-fattening`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/www.tobaccofreequebec.ca/iquitnow/getting-ready-to-quit/mental-roadblocks/smoking-my-choice') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/smoking-my-choice`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/www.tobaccofreequebec.ca/iquitnow/getting-ready-to-quit/mental-roadblocks/life-boring') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/life-boring`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/www.tobaccofreequebec.ca/iquitnow/getting-ready-to-quit/mental-roadblocks/dying-from-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/dying-from-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/www.tobaccofreequebec.ca/iquitnow/getting-ready-to-quit/mental-roadblocks/dangers-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/dangers-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/www.tobaccofreequebec.ca/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-withdrawal') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-withdrawal`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/vie-ennuyante') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/life-boring`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/vaccin') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/vaccine-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/trucs-cesser-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/tips-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/reactions-entourage') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/others-reactions`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/pire-que-la-cigarette') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/worse-things-than-cigarettes`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/panique-cesser-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/panic-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/mourir-de-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/dying-from-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/interdiction-de-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/government-would-ban-it`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/grand-pere') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/grandfather`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/fumer-stress') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stress-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/dangers-cigarette') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/dangers-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/craindre-sevrage') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-withdrawal`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/continuer-a-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/smoking-my-choice`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/benefices-sante') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/health-benefits`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/arreter-sans-grossir') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stop-without-fattening`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/arreter-de-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/cannot-stop-willpower`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/access/www.tobaccofreequebec.ca/iquitnow/access/pharmacies') {
            return navigateTo(`/iquitnow/access/pharmacies`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/access/www.tobaccofreequebec.ca/iquitnow/access/health-facilities') {
            return navigateTo(`/iquitnow/access/health-facilities`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow.ALCOHOL') {
            return navigateTo(`/iquitnow`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/inscription') {
            return navigateTo(`/iquitnow/registration`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/information') {
            return navigateTo(`${config.public.frDomain}/je-minforme`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/_nuxt/') {
            return navigateTo(`/iquitnow`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/blogue/5-conseils-pour-faire-face-au-stress-sans-allumer-une-cigarette') {
            return navigateTo(`${config.public.frDomain}/je-minforme/actualites/blogue/6-conseils-pour-faire-face-au-stress-sans-allumer-une-cigarette`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/worse-things-than-cigarettes') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/worse-things-than-cigarettes`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/vaccine-quitting-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/vaccine-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/tips-quitting-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/tips-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/panic-quitting-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/panic-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/others-reactions') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/others-reactions`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/natural-cigarettes') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/natural-cigarettes`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/government-would-ban-it') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/government-would-ban-it`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/cannot-stop-willpower') {
            return navigateTo(`}/iquitnow/getting-ready-to-quit/mental-roadblocks/cannot-stop-willpower`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/afraid-of-relapse') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-of-relapse`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblock/smoking-my-choice') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/smoking-my-choice`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblock/dangers-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/dangers-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/your-cravings') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/your-cravings`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/trucs-cesser-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/tips-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/tips-quitting-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/tips-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/stress-smoking') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stress-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/rechute-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-of-relapse`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/interdiction-de-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/government-would-ban-it`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/fumer-stress') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stress-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/still-hesitating/mental-roadblocks/craindre-sevrage') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-withdrawal`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/vos-envies') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/your-cravings`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/vie-ennuyante') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/life-boring`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/vaccin') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/vaccine-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/trucs-cesser-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/tips-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/rechute-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-of-relapse`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/reactions-entourage') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/others-reactions`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/panique-cesser-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/panic-quitting-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/mourir-de-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/dying-from-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/grand-pere') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/grandfather`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/fumer-stress') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stress-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/dangers-cigarette') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/dangers-smoking`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/craindre-sevrage') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/afraid-withdrawal`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/continuer-a-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/smoking-my-choice`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/cigarettes-naturelles') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/natural-cigarettes`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/benefices-sante') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/health-benefits`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/arreter-sans-grossir') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/stop-without-fattening`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/iquitnow/getting-ready-to-quit/mental-roadblocks/arreter-de-fumer') {
            return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/cannot-stop-willpower`, {
               external: false,
               redirectCode: 301
            });
         } else {
            let roadblock = to.path.match(/\/iquitnow\/getting-ready-to-quit\/mental-roadblock\/(.+)/)
            if (roadblock) {
               return navigateTo(`/iquitnow/getting-ready-to-quit/mental-roadblocks/${roadblock[1]}`, {
                  external: false,
                  redirectCode: 301
               });
            }
         } 
      } else if (host.match(config.public.frDomain.replace(/(http(s)?:\/\/)?(www\.)?/, "")) ) {
         if (to.path === '/sitemap') {
            return navigateTo(`/plan-du-site`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/_nuxt/') {
            return navigateTo(`/`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/form/f') {
            return navigateTo(`/jarrete/acces-professionnels/pharmacie`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/form/formulaire-2') {
            return navigateTo(`/jarrete/acces-professionnels/etablissements-de-sante`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/form/patient-reference-form-from-hosp') {
            return navigateTo(`${config.public.enDomain}/iquitnow/access/health-facilities`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/form/patient-reference-form-from-phar') {
            return navigateTo(`${config.public.enDomain}/iquitnow/access/pharmacies`, {
               external: true,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/blogue/5-conseils-pour-faire-face-au-stress-sans-allumer-une-cigarette') {
            return navigateTo(`/je-minforme/actualites/blogue/6-conseils-pour-faire-face-au-stress-sans-allumer-une-cigarette`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/campagnes-mediatiques/semaine-pour-un-quebec-sans-tabac-pubs-et-temoignages') {
            return navigateTo(`/je-minforme/actualites/campagnes-mediatiques/semaine-pour-un-quebec-sans-tabac-pubs-et-temoignages-video`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/campagnes-mediatiques/le-defi-jarrete-jy-gagne-pour-ecraser-et-profiter-des') {
            return navigateTo(`/je-minforme/actualites/campagnes-mediatiques/le-defi-jarrete-jy-gagne-pour-ecraser-et-profiter-des-bienfaits`, {
               external: false,
               redirectCode: 301
            });
         } else if (to.path === '/je-minforme/actualites/enjeux-actuels/un-megot-ca-va-dans-les-poubelles-ca-coule-de-source') {
            return navigateTo(`/je-minforme/actualites/enjeux-actuels/un-megot-de-cigarette-ca-va-dans-les-poubelles-ca-coule-de-source`, {
               external: false,
               redirectCode: 301
            });
         }
      }
});