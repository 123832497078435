import gql from "graphql-tag";

export default gql`
  query home($siteId: [QueryArgument]) {
    home: entry(section: "home", siteId: $siteId) {
      ... on home_home_Entry {
        title
        homeimage1 {
          url
        }
        homeimage2 {
          url
        }
        videoTitle
        video
        sectionCategory {
          ... on sections_Category {
            title
            longTitle
            description
            id
            image {
              url
            }
            seo {
              description
              title
            }
          }
        }
        stopSection {
          ... on stopSection_stopSection_BlockType {
            id
            sectionTitle
            sectionSubtitle
            supportTitle
            supportSubtitle
            onlineSupportContent
            telSupportContent
            personSupportContent
          }
        }
        sectionFacebook {
          ... on sectionFacebook_sectionFacebook_BlockType {
            facebookTitle
            subtitle
            btn
          }
          ... on sectionFacebook_slide_BlockType {
            firstName
            slideTitle
            slideContent
            image {
              url
            }
          }
        }
        sectionPreparationHome {
          ... on sectionPreparationHome_sectionpreparationhome_BlockType {
            preparationTitle
            preparationContent1
            preparationContent2
            preparationBtn
          }
        }
        seo {
          description
          title
        }
      }
    }
    informCategories: categories(relatedToCategories: { id: "105" }, siteId: $siteId) {
      ... on topics_Category {
        id
        title
        subtitle
        longTitle
        description
        slug
        url
        icon {
          url
        }
        image {
          png_image1x: url(width: 270, height: 160, format: "png")
          png_image2x: url(width: 540, height: 320, format: "png")
          png_image3x: url(width: 810, height: 480, format: "png")
          webp_image1x: url(width: 270, height: 160, format: "webp")
          webp_image2x: url(width: 540, height: 320, format: "webp")
          webp_image3x: url(width: 810, height: 480, format: "webp")
          ... on images_Asset {
            url
          }
        }
        seo {
          description
          title
        }
      }
    }
    preventCategories: categories(relatedToCategories: { id: "106" }, siteId: $siteId) {
      ... on topics_Category {
        id
        title
        subtitle
        longTitle
        description
        slug
        url
        icon {
          url
        }
        image {
          png_image1x: url(width: 270, height: 160, format: "png")
          png_image2x: url(width: 540, height: 320, format: "png")
          png_image3x: url(width: 810, height: 480, format: "png")
          webp_image1x: url(width: 270, height: 160, format: "webp")
          webp_image2x: url(width: 540, height: 320, format: "webp")
          webp_image3x: url(width: 810, height: 480, format: "webp")
          ... on images_Asset {
            url
          }
        }
      }
    }
    stopCategories: categories(relatedToCategories: { id: "107" }, siteId: $siteId) {
      ... on topics_Category {
        id
        title
        longTitle
        slug
        url
        icon {
          url
        }
        image {
          png_image1x: url(width: 270, height: 160, format: "png")
          png_image2x: url(width: 540, height: 320, format: "png")
          png_image3x: url(width: 810, height: 480, format: "png")
          webp_image1x: url(width: 270, height: 160, format: "webp")
          webp_image2x: url(width: 540, height: 320, format: "webp")
          webp_image3x: url(width: 810, height: 480, format: "webp")
          ... on images_Asset {
            url
          }
        }
        seo {
          description
          title
        }
      }
    }
    entries: entries(section: "article", siteId: $siteId) {
      ... on article_default_Entry {
        title
        slug
        excerpt
        icon {
          url
        }
        topicCategory {
          id
        }
      }
    }
    news: entries(
      section: "article"
      limit: 4
      relatedToCategories: { id: "114" }
      siteId: $siteId
      orderBy: "postDate DESC"
    ) {
      ... on article_default_Entry {
        title
        image {
          png_image1x: url(width: 270, height: 160, format: "png")
          png_image2x: url(width: 540, height: 320, format: "png")
          png_image3x: url(width: 810, height: 480, format: "png")
          webp_image1x: url(width: 270, height: 160, format: "webp")
          webp_image2x: url(width: 540, height: 320, format: "webp")
          webp_image3x: url(width: 810, height: 480, format: "webp")
          ... on images_Asset {
            url
          }
        }
        excerpt
        postDate @formatDateTime(format: "d F Y")
        articleTags {
          ... on tags_Tag {
            title
            slug
          }
        }
        slug
        topicCategory {
          url
        }
      }
    }
  }
`;
